<template>
    <div class="c-location-card">
        <div class="c-location-card__column">
            <div class="c-location-card__title" v-html="title"></div>

            <div v-if="address" class="c-location-card__address" v-html="address"></div>

            <NuxtLink class="c-location-card__button" :to="stripBaseUrl(landing_page)"> View details </NuxtLink>
        </div>

        <div class="c-location-card__column">
            <div v-if="ownership_items && ownership_items.items.length > 0" class="c-location-card__checklist-wrapper">
                <div
                    v-if="ownership_items.title"
                    class="c-location-card__checklist-title"
                    v-html="ownership_items.title"></div>

                <ul class="c-location-card__checklist">
                    <li
                        v-for="(item, index) in ownership_items.items"
                        :key="index"
                        class="c-location-card__checklist-item"
                        v-html="item.item"></li>
                </ul>
            </div>

            <div v-if="holiday_items && holiday_items.items.length > 0" class="c-location-card__checklist-wrapper">
                <div
                    v-if="holiday_items.title"
                    class="c-location-card__checklist-title"
                    v-html="holiday_items.title"></div>

                <ul class="c-location-card__checklist">
                    <li
                        v-for="(item, index) in holiday_items.items"
                        :key="index"
                        class="c-location-card__checklist-item"
                        v-html="item.item"></li>
                </ul>
            </div>
        </div>

        <NuxtLink class="c-location-card__button is-mobile" :to="stripBaseUrl(landing_page)"> View details </NuxtLink>
    </div>
</template>

<script setup>
    const props = defineProps({
        address: {
            type: String,
            required: false,
        },
        holiday_items: {
            type: Array,
            required: false,
        },
        landing_page: {
            type: String,
            required: false,
        },
        ownership_items: {
            type: Array,
            required: false,
        },
        title: {
            type: String,
            required: true,
        },
    });

    function stripBaseUrl(url) {
        return url.replace(/^https?:\/\/[^/]+/, "");
    }
</script>
